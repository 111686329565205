html,
body {
	background-color: #343434;
	color: white;
}

.App {}

#hero-image {
	height: 350px;
}


@media (min-width: 600px) {
	header #desktopNav {
		display: flex;
	}

	#hero-image {
		height: 500px;
		width: 100%;
	}
}